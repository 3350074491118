import socket from './socket.js';
import numbers from './numbers.js';

/* Getting the show on the road */
document.addEventListener('DOMContentLoaded', () => {
	socket.init();
	numbers.init();

	socket.initialiseWebsocket();
	socket.initialiseNumbersSocket();
});
